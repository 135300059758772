#home-container {
    background: linear-gradient(145deg, var(--indigo) 50%, var(--dark) 50%);
  }
  .h-100 {
    min-height: 100vh;
  }
  @media (max-width: 42.0rem) {
    #home-container {
      min-height: 100vh;
      background: linear-gradient(145deg, var(--dark) 50%, var(--indigo) 50%);
    }
    .home-image {
      display: inline-block;
      width: 250px;
      height: 250px;
      border-radius: 50%;
      overflow: hidden;
      background: var(--indigo);
      max-width: unset;
    }
    .btn-indigo {
      background: var(--dark);
      border: 0.5px solid var(--dark);
    }
    .btn-indigo:hover {
      background: var(--dark-hover);
      border: 0.5px solid var(--dark-hover);
    }
    .h-100 {
      padding-top: unset;
      margin-top: 5em;
    }
}