.img-responsive img {
    max-width: 100%;
    width: unset;
}

.modal .modal-inner {
    max-height: unset;
    top: 10vh;
    background: white;
}

.modal {
    overflow: auto;
}

.modal .btn {
    color: white !important;
}

.modal::before {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0; 
    z-index: 550;
}