@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400&display=swap');

:root {
  --text-color: black;
  --bg-color: #F8FAFD;
  --active-color: #e2e2e2;
  --hover-color: #b4b4b4;
  --icon-invert: none;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
  /* border-radius: 0px; */
}

::-webkit-scrollbar {
  width: 0px;
  height: 2px;
  /* width: 0px; */
}

::-webkit-scrollbar-thumb {
  /* border-radius: 0px; */
  border-radius: 10px;
  background-color: var(--dark);
}

html,
body {
  font-family: "Roboto", sans-serif;
  background: var(--bg-color);
  color: var(--text-color);
  transition: all .005s !important;
  scroll-behavior: smooth;
  padding: 0 !important;
  margin: 0 !important;
}

/* Loader */
.loader {
  position: absolute;
  z-index: 5;
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
}

.la-ball-grid-pulse,
.la-ball-grid-pulse>div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-grid-pulse {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-grid-pulse.la-dark {
  color: #333;
}

.la-ball-grid-pulse>div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-grid-pulse {
  width: 36px;
  height: 36px;
}

.la-ball-grid-pulse>div {
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 100%;
  -webkit-animation-name: ball-grid-pulse;
  -moz-animation-name: ball-grid-pulse;
  -o-animation-name: ball-grid-pulse;
  animation-name: ball-grid-pulse;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.la-ball-grid-pulse>div:nth-child(1) {
  -webkit-animation-duration: .65s;
  -moz-animation-duration: .65s;
  -o-animation-duration: .65s;
  animation-duration: .65s;
  -webkit-animation-delay: .03s;
  -moz-animation-delay: .03s;
  -o-animation-delay: .03s;
  animation-delay: .03s;
}

.la-ball-grid-pulse>div:nth-child(2) {
  -webkit-animation-duration: 1.02s;
  -moz-animation-duration: 1.02s;
  -o-animation-duration: 1.02s;
  animation-duration: 1.02s;
  -webkit-animation-delay: .09s;
  -moz-animation-delay: .09s;
  -o-animation-delay: .09s;
  animation-delay: .09s;
}

.la-ball-grid-pulse>div:nth-child(3) {
  -webkit-animation-duration: 1.06s;
  -moz-animation-duration: 1.06s;
  -o-animation-duration: 1.06s;
  animation-duration: 1.06s;
  -webkit-animation-delay: -.69s;
  -moz-animation-delay: -.69s;
  -o-animation-delay: -.69s;
  animation-delay: -.69s;
}

.la-ball-grid-pulse>div:nth-child(4) {
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: -.41s;
  -moz-animation-delay: -.41s;
  -o-animation-delay: -.41s;
  animation-delay: -.41s;
}

.la-ball-grid-pulse>div:nth-child(5) {
  -webkit-animation-duration: 1.6s;
  -moz-animation-duration: 1.6s;
  -o-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-delay: .04s;
  -moz-animation-delay: .04s;
  -o-animation-delay: .04s;
  animation-delay: .04s;
}

.la-ball-grid-pulse>div:nth-child(6) {
  -webkit-animation-duration: .84s;
  -moz-animation-duration: .84s;
  -o-animation-duration: .84s;
  animation-duration: .84s;
  -webkit-animation-delay: .07s;
  -moz-animation-delay: .07s;
  -o-animation-delay: .07s;
  animation-delay: .07s;
}

.la-ball-grid-pulse>div:nth-child(7) {
  -webkit-animation-duration: .68s;
  -moz-animation-duration: .68s;
  -o-animation-duration: .68s;
  animation-duration: .68s;
  -webkit-animation-delay: -.66s;
  -moz-animation-delay: -.66s;
  -o-animation-delay: -.66s;
  animation-delay: -.66s;
}

.la-ball-grid-pulse>div:nth-child(8) {
  -webkit-animation-duration: .93s;
  -moz-animation-duration: .93s;
  -o-animation-duration: .93s;
  animation-duration: .93s;
  -webkit-animation-delay: -.76s;
  -moz-animation-delay: -.76s;
  -o-animation-delay: -.76s;
  animation-delay: -.76s;
}

.la-ball-grid-pulse>div:nth-child(9) {
  -webkit-animation-duration: 1.24s;
  -moz-animation-duration: 1.24s;
  -o-animation-duration: 1.24s;
  animation-duration: 1.24s;
  -webkit-animation-delay: -.76s;
  -moz-animation-delay: -.76s;
  -o-animation-delay: -.76s;
  animation-delay: -.76s;
}

.la-ball-grid-pulse.la-sm {
  width: 18px;
  height: 18px;
}

.la-ball-grid-pulse.la-sm>div {
  width: 4px;
  height: 4px;
  margin: 1px;
}

.la-ball-grid-pulse.la-2x {
  width: 72px;
  height: 72px;
}

.la-ball-grid-pulse.la-2x>div {
  width: 16px;
  height: 16px;
  margin: 4px;
}

.la-ball-grid-pulse.la-3x {
  width: 108px;
  height: 108px;
}

.la-ball-grid-pulse.la-3x>div {
  width: 24px;
  height: 24px;
  margin: 6px;
}

/*
   * Animation
   */
@-webkit-keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: .35;
    -webkit-transform: scale(.45);
    transform: scale(.45);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: .35;
    -moz-transform: scale(.45);
    transform: scale(.45);
  }

  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: .35;
    -o-transform: scale(.45);
    transform: scale(.45);
  }

  100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: .35;
    -webkit-transform: scale(.45);
    -moz-transform: scale(.45);
    -o-transform: scale(.45);
    transform: scale(.45);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover,
a:focus,
a:visited {
  color: var(--primary-color);
  /* text-decoration: none; */
}

.navbar-collapse {
  -webkit-appearance: none;
  border-radius: 0;
  font-size: 1.5rem;
}

.mobile-toggler {
  position: absolute;
  color: white;
  font-size: 1.2em;
  font-weight: 400;
  right: 1.5em;
  top: 1.5em;
}

.mobile-toggler:hover {
  cursor: pointer;
  color: lightgrey;
}

.mobile-toggler-other {
  /* position: absolute; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /* padding: 1.5em; */
  font-size: 1.2em;
  font-weight: 400;
  background: #F8FAFD;
  color: var(--indigo);
}

.mobile-toggler-other:hover {
  cursor: pointer;
  color: black;
}

.btn {
  border-radius: 0 !important;
}

.btn-light {
  color: var(--dark) !important;
}

.btn-link {
  border: 1px solid black;
  color: black !important;
}

.navbar-dark {
  color: white;
}

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-7 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-8 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.spacer {
  display: block;
  padding: 2em 0em;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-warning {
  background: #ff8a00 !important;
  color: white !important;
}

nav {
  background: var(--bg-color);
  /* padding-top: 0 !important; */
  /* padding-bottom: 0 !important; */
  /* padding-right: 0 !important; */
  /* margin-top: 0 !important; */
  /* margin-bottom: 0 !important; */
  /* border-top: 5px solid var(--primary-color); */
}

nav .navbar-brand {
  padding: 0;
}

nav .navbar-toggler {
  color: var(--icon-invert) !important;
}

nav ul .nav-link {
  color: #373739 !important;
}


nav ul .nav-item {
  text-transform: uppercase;
  font-size: 0.8rem;
}

ul.mobile-social-nav>li {
  display: inline-block;
}

ul.mobile-social-nav>li>a>i {
  font-size: 0.75em;
}

ul.mobile-social-nav {
  padding: 0;
}

.navbar-transparent {
  background: transparent;
  color: var(--primary-color);
}

.navbar-transparent a.active {
  font-weight: 400;
}

.navbar-transparent a,
.navbar-transparent a.active,
.navbar-transparent a:hover {
  color: var(--primary-color);
}

.navbar-transparent a:hover {
  font-weight: 400;
}

.navbar-transparent a.navbar-collapse {
  color: var(--primary-color);
}

.navbar-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

nav ul .nav-item.active {
  font-weight: bold;
}

.navbar-toggler {
  border: 0;
  outline: none;
}

.moonIcon {
  color: var(--icon-invert);
}

.sunIcon {
  color: var(--icon-invert);
  display: none;
}

.hero {
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  align-content: center;
}

.hero.fifty {
  height: 50vh;
}

.hero.seventy {
  height: 70vh;
}

.hero.background {
  /* background: url("./imgs/bg.png"); */
  background-size: cover;
  background-position-y: right;
  background-repeat: no-repeat;
}


/* #hero .btn {
    background: var(--primary-color);
    color: white;
    transition: all 0.3s;
}
#hero .btn:hover {
    -webkit-box-shadow: 5px 5px 20px -5px var(--hover-color);
    -moz-box-shadow: 5px 5px 20px -5px var(--hover-color);
    box-shadow: 5px 5px 20px -5px var(--hover-color);
} */

#hero-social {
  margin: 2em 0;
}

#hero-social a {
  color: var(--icon-invert);
  text-decoration: none;
}

#hero-social a:hover {
  text-decoration: none;
}

.hover {
  text-align: center;
  /* border-radius: 5px; */
  transition: all 0.3s;
}

.hover:hover {
  -webkit-box-shadow: 5px 5px 20px -10px var(--hover-color);
  -moz-box-shadow: 5px 5px 20px -10px var(--hover-color);
  box-shadow: 5px 5px 20px -10px var(--hover-color);
}

#whoAreWe {
  padding: 1em;
  margin: 5em 0;
}

.previousCustomers {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.previousCustomers>img {
  margin: 0 auto;
  max-height: 4em !important;
  max-width: 80% !important;
}

.pushButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pushButton p {
  flex-grow: 1;
}

.pushButton p:first-child {
  line-height: 2;
}

.pushButton button {
  text-align: center;
  margin: 1em auto;
}

#whatWeOffer>div>div {
  text-align: center;
  padding: 1em;
  margin-bottom: 2em;
  /* background: var(--bg-color); */
  transition: all 0.8s;
}

footer {
  vertical-align: top;
}

footer ul {
  list-style-type: none;
}

footer ul li {
  display: inline;
  font-size: large;
  padding-right: 1em;
  text-transform: uppercase;
}

footer a {
  color: var(--text-color) !important;
}

footer a:visited,
footer a:focus,
footer a:hover {
  text-decoration: none;
}

.footer-flexbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40vh;
}

.footer-flex>.display-7 {
  font-size: 1.5em !important;
}

.footer-flexbox a {
  color: var(--primary-color) !important;
}

.footer-flexbox:nth-child(2) {
  background: var(--text-color);
  color: var(--bg-color);
  padding: 3em;
}

.footer-flexbox:nth-child(2) a {
  line-height: 2;
}

@media (max-width: 992px) {
  nav ul {
    background: var(--bg-color);
  }

  nav .navbar-toggler {
    margin-right: 16px;
  }

  footer img {
    width: 40vw !important;
  }

  .previousCustomers>img {
    max-height: 4em !important;
    max-width: 75% !important;
  }
}

@media (max-width: 772px) {
  .profile img {
    width: 65%;
  }

  #services-sections h3 {
    font-size: 1.5em !important;
  }

  #services-sections p.lead {
    font-size: 1em !important;
  }

  .hero .display-5 {
    font-size: 1.8em !important;
  }

  .hero .display-7 {
    font-size: 1.3em !important;
  }
}

.lead {
  font-weight: normal;
}


/* Mobile navigation */

.transformToLeft {
  max-height: 100vh;
  /* z-index: 5; */
  overflow: hidden;
  box-shadow: 0 0 60px var(--hover-color);
  transform: translateX(-300px) scale(0.6);
  transform-origin: 100% 50%;
  transition: all cubic-bezier(0.77, 0, 0.175, 1) 1.5s;
  background: white;
}

.transformToLeft.hidden {
  transform: translateX(0px);
  overflow: auto;
}

.mobileNavigation {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 300px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mobileNavigation .lead {
  padding: 1em 0;
  font-size: 1.7em;
}

.mobileNavigation ul {
  list-style: none;
  font-size: 1.5em;
  font-weight: 300;
  /* line-height: 2; */
}

.mobileNavigation ul li.active a {
  border-left: 2px solid var(--indigo);
}

.mobileNavigation ul li a {
  padding: 0 1em;
  text-decoration: none;
  color: var(--text-color);
}

#mobileNav {
  position: relative;
  z-index: 1;
}

#mobileNav .close {
  position: absolute;
  top: 30px;
  right: 30px;
  color: var(--text-color);
  cursor: pointer;
}

#content {
  position: relative;
  z-index: 2;
  background: white;
  min-height: 100vh;
}

.lighter-background {
  background: #F8FAFD !important;
  /* background: var(--indigo) !important; */
  min-height: calc(100vh - 88px);
}


.h-100 {
  margin-top: unset;
  min-height: calc(100vh - 88px);
  max-width: unset;
}

select,
option {
  -webkit-appearance: none;
}

.select-wrapper {
  position: relative;
  display: inline-block;
}

.select-wrapper:after {
  content: '\0025BC';
  color: white;
  right: 14px;
  top: 4px;
  height: 26px;
  padding: 15px 0px 0px 8px;
  position: absolute;
  pointer-events: none;
}

select option:disabled {
  color: #000;
  font-weight: bold;
}

@media (max-width: 42.0rem) {

  /* footer {
        margin-top: 2em;
    } */
  .home-image {
    /* border-radius: 50%; */
    height: auto;
    max-width: 200px;
    margin: 0 auto;
  }

  .bottom-align {
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
  }

  .h-100 {
    padding-top: 5em;
    min-height: unset !important;
  }

  .lead {
    /* font-size: 100; */
    font-size: 1.1em;
    /* margin: 2em 1em; */
  }

  .home-content {
    margin-bottom: 5em;
  }
}

.home-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-align {
  display: flex;
  align-items: flex-end;
}

/* 
    Forces the browser to disable the content section from text
    selection (the smaller transformed version of it only) 
*/

#content[class*="transformToLeft"]:not(.hidden) {
  pointer-events: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}



footer br {
  margin-top: 1em;
}

.ms-n5 {
  margin-left: -40px;
  border-radius: 50% !important;
}

.footer-socials a {
  padding: 0em 1em;
}

.footer-socials i {
  color: white;
}


.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.user-avatar {
  display: inline-block;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: 2;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-4.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}


details {
  margin: 2em 0em 0em 0.5em;
  font-size: 1.2em;
  font-weight: 300;
}

summary {
  font-weight: bold;
  margin-left: -0.5em;
  margin-bottom: 0.75em;
}

.circle {
  position: relative;
}

.text-block {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

/* .circle-inner {
    background: url('../');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
} */



.card {
  margin: 30px 30px;
  width: 300px;
  height: 300px;
  border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: 0.4s;
  display: inline-block;
}

@media (max-width: 42.0rem) {
  .card {
    margin: 30px auto;
  }
}

.card .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card .card_image img {
  width: inherit;
  height: inherit;
  border-radius: 40px;
  object-fit: cover;
}

.card .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  /* margin-top: -80px; */
  height: 40px;
  color: var(--dark);
}

.card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

p {
  hyphens: none;
}