.hero {
    height: unset;
}

.container a {
    text-decoration: underline;
}

.resume-blocks li {
    text-indent: 0.5em;
}

.resume-blocks>p.lead {
    margin-top: 1.2em;
    margin-bottom: 0;
}

.resume-blocks>p {
    margin-top: 0;
    margin-bottom: 0;
}

.resume-blocks a {
    text-decoration: none;
}

.resume-blocks a::after {
    content: " \02197";
}

.resume-blocks:last-child {
    margin-bottom: 2.5em;
}

.resume-blocks .btn {
    color: white !important;
}

.resume-blocks a.btn::after {
    content: "";
}

.img-responsive a::after {
    content: "";
}