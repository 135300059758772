@media (max-width: 42.0rem) {
    .center-on-mobile {
        margin-top: 20%;
        text-align: center;
    }
}

.container a {
    text-decoration: underline;
}

.hero {
    height: unset;
}