@import url(https://unpkg.com/notie/dist/notie.min.css);
input, select {
    width: unset !important;
}

label {
    font-size: 1.2em;
    font-weight: bold;
}

.hero {
    height: unset;
}

.container a {
    text-decoration: underline;
}

@media (max-width: 42.0rem) {
    label {
        display: block;
        width: 100%;
    }
}

.notie-background-success {
    background: var(--indigo) !important;
    font-size: 1.2em;
}

.notie-background-warning {
    background: var(--dark) !important;
    font-size: 1.2em;
}